button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.App {
  text-align: center;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: 100.1%;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: space-between;
}

.page{
  height: 100.1%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
}

.pageDark{
  background-color: #272727;
}

.chartContainer{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.doughnut{
  position: absolute;
  z-index: 0;
}

.chartText{
  font-size: clamp(8px, min(2.3vh, 4.6vw),24px);
  font-weight: 700;
  color: #364262;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
  padding-top: 18%;
}

.chartTextDark{
  color:#eeeeee;
}

.chartTextPeriod{
  white-space: pre-wrap;
  padding: 4px;
  font-weight: 900;
  color: #283048
}

.chartTextPeriodDark{
  color: #ffffff
}

.takeHomeText{
  font-size: 120%;
  font-weight: 800;
  display: inline;
  color: #283048
}

.takeHomeTextDark{
  color: #ffffff
}

.swiper{
  width: clamp(240px, min(80vw,40vh), 415px);
  height: clamp(240px, min(80vw,40vh), 415px);
  border-radius: 50%;
}

.chartTextBody{
  position: absolute;
}

.salaryContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: clamp(10px, 3vw, 30px);
  color: rgba(50, 50, 50, 0.3);
  width: 100%;
  flex:1;
}

.salaryLabel{
  display: block;
  color:#383838;
  background: none;
  font-weight: 700;
  font-size: clamp(8px, 4vw, 24px);
  margin-bottom: 12px;
  user-select: none;
  -webkit-user-select: none;
}

.salaryLabelDark{
  color: #eeeeee;
}

.salaryInput{
  flex-wrap: wrap;
  border: none;
  background-color: #eeeeee;
  font-family: poppins;
  color:#383838;
  font-size: clamp(10px, 4vw, 30px);
  width: clamp(70px, 25vw, 200px);
  padding: 4px 0px;
  text-align: center;
  border-radius: 0px;
}

.salaryInputDark{
  background-color: #444444;
  color:#eeeeee
}

.sliderContainer{
  background-color: rgba(255, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: rgba(155, 0, 0, 0.3);
  width: 100%;
  height: 10vh;
}

.optionsContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  padding: 0px 20px;
  box-sizing: border-box;
}

.flagsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 120px;
}

.locationLabel{
  font-size: clamp(10px, 3.5vw, 20px);
  color: #383838;
}

.locationLabelDark{
  color:#eeeeee;
}

.flags{
  width: clamp(10px, 20vw, 120px);
  height: auto;
  background-size: cover;
  box-sizing: border-box;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.taxCodeContainer{
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.taxCodeLabel{
  display: block;
  color:#383838;
  background: none;
  font-weight: 700;
  font-size: clamp(8px, 4vw, 24px);
  margin-bottom: 12px;
  user-select: none;
  -webkit-user-select: none;
}

.taxCodeLabelDark{
  color: #eeeeee;
}

.taxCodeInput{
  border: none;
  background-color: #eeeeee;
  font-family: poppins;
  color:#383838;
  font-size: clamp(10px, 4vw, 30px);
  width: clamp(70px, 25vw, 200px);
  padding: 4px 0px;
  text-align: center;
  border-radius: 0px;
}

.taxCodeInputDark{
  background-color: #444444;
  color:#eeeeee
}

.bottomLinksContainer{
  color: #c02323;
  margin-top: 55px;
  white-space: pre-wrap;
}

.bottomLinks{
  text-decoration: underline;
}

.bottomLinksContainerDark{
  color: #ef5d5d;
}

.questionMark{
  width: 120px;
  font-size: 60px;
  font-weight: 900;
  align-self: flex-end;
  justify-self: right;
  text-align: right;
  box-sizing: border-box;
  color: #000000;
}

.questionMarkDark{
  color: #ffffff;
}

#header {
  background-color: #5D5B91;
  display: flex;
  font-weight: 900;
  flex-direction: row;
  font-size: clamp(10px, 6.5vw, 36px);
  align-items: baseline;
  justify-content: space-between;
  padding-left: clamp(0px, 1vh, 36px);
  color: white;
  padding-top: clamp(6px, 2vw, 14px);
  padding-bottom: clamp(5px, 1.4vw, 10px);
  box-sizing:border-box;
  
}

.headerIcon{
  width: clamp(15px, 7vw, 40px);
  margin-right: clamp(0px, 1vw, 4px);
}

.headerText{
  height: 100%;
}


#dotcodotuk{
  display: inline;
  font-size: 65%;
  font-style: italic;
  font-weight: 600;
}

.darkModeIcon{
  width: clamp(20px, 8vw, 40px);
  height: clamp(20px, 8vw, 40px);
  margin-right: clamp(4px, 6vw, 30px);
  position: relative;
  background-color: rgba(0, 0, 0, .2);
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: clamp(0px, 4vw, 5px);
}

.darkModeIconDark{
  background-color: rgba(200, 200, 200, .2);
}
 
.darkModeIcon img{
  position: absolute;
  width: 60%;
  height: 60%;
  transition: transform .5s,
}

#footer{
  background-color: #383838;
  padding: 4px 0px;
  color: rgb(214, 214, 214)
}


.extraOptionsContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: #eeeeee;
  position: fixed;
  left: 0;
  bottom: 0;
  transition: transform .3s ease-out;
  width: 100vw;
  z-index: 10;
  border: #bcbcbc 3px solid;
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.extraOptionsContainerDark{
  background-color: #404040;
  border: #292929 3px solid;
  color: #eeeeee;
}

.extraOptionsContainer.hide {
  transform: translateY(101%); 
}
 
.extraOptionsContainer.show {
  overflow: hidden;
}

.extraOptionsContainer div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 16px 0px;
} 

.extraOptionsContainer button{
  width: 100%;
  font-size: 22px;
  color: #860000;
  font-weight: 900;
}

.extraOptionsClose{
  margin: 10px 0px;
  height: 30px;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.extraOptionsCloseDark{
  filter: invert(99%) sepia(0%) saturate(529%) hue-rotate(148deg) brightness(87%) contrast(84%);
}

.extraOptionsLabel{
  font-size: 22px;
  color: #383838;
  font-weight: 900;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.extraOptionsLabelDark{
  color: #eeeeee;
}

.extraOptionsInput[type="checkbox"]{
  width: 30px;
  height: 50px;
} 

.extraOptionsInput[type="text"]{
  font-size: 20px;
  width: 90px;
  text-align: center;
  font-family: Poppins;
} 

.adviceMenuContainer{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  bottom: 0px;
  z-index: 20;
  background-color: #ffffff;
  border: #252525 3px solid;
  box-shadow: 0px 0px 15px 2px rgba(0,0,0,.2);
  box-sizing: border-box;
  overflow: hidden;
  transition: .15s ease;
  touch-action: none;
}

.adviceMenuContainerDark{
  background-color: #383838;
  border: #131313 3px solid;
}

.adviceMenuContainer.show{
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.adviceMenuContainer.hide{
  visibility: hidden;
  transform: translateY(300px);
  opacity: 0;
}

.adviceMenuContainerCloseBtn{
  width: 100%;
  padding: 5px 10px;
  background-color: #252525;
  color: #eeeeee;
  text-align: left;
  font-size: 30px;
  vertical-align: baseline;
}

.adviceMenuContainerCloseBtn img{
  width: auto;
  height: 20px;
  margin-right: 4px;
}

.blurBackdrop{
  position: fixed;
  left: -100vw;
  top: -100vh;
  width: 200vw;
  height: 200vh;
  backdrop-filter: blur(0px);
  transition: backdrop-filter .3s ease;
  touch-action: none;
}

.blurBackdrop.show{
  visibility: visible;
}

.blurBackdrop.hide{
  visibility: none;
  backdrop-filter: none;
  pointer-events: none;
}

.adviceCardHeader{
  position: relative;
  width: 100%;
  font-size: 24px;
  color: #414141;
  padding: 24px 12px;
  text-align: left;
  font-family: "Inter";
  font-weight: 600;
}

.adviceCardHeaderDark{
  color: #eeeeee;
}

.adviceCardContent{
  width: 100%;
  font-size: 16px;
  color: #222222;
  transition: height 0.3s, padding-top 0.3s;
  object-fit: cover;
  overflow: hidden;
  background-color: #ececec;
  text-align: left;
  padding: 24px;
  font-family: "Inter";
  box-sizing: border-box;
  line-height: 150%;
}

.adviceCardContentDark{
  background-color: #4d4d4d;
  color: #ffffff;
}

.adviceCardContent.show{
  height:1500px;
}

.adviceCardContent.hide{
  height: 0px;
  border-bottom: none;
  padding-top: 0px;
  padding-bottom: 0px;
}

.adviceCardContent a{
  font-weight: bold;
  text-decoration: underline;
}

.adviceCardLine{
  border: 1px solid #d3d3d3;
  margin: 0px;
}

.adviceCardLineDark{
  border: 1px solid #252525;
}

.adviceCardLine.show{
  visibility: visible;
}

.adviceCardLine.hide{
  visibility: hidden;
}

.finger{
  transition: transform .1s;
  transform: rotate(0deg);
  display: inline-block;
  margin-right: 6px;
}

.finger.spin{
  transform: rotate(90deg);
}

.scrollable{
  overflow-y: scroll;
  padding-top: 40px;
  height: 90%;
}

.salaryBracketsTable{
  text-align: center;
}

.salaryBracketsTable td{
  width: 150px;
  padding: 10px;
  background-color: #ffffff;
}